import Footer from './Components/Footer/Footer'
import Main from "./Components/Main/Main";
import Navbar from "./Components/Navbar/Navbar";


function App() {
  return (
    <div>
<Navbar/>
<Main/>
<Footer/>
    </div>
  );
}

export default App;
